//this is my firebase.js
import { initializeApp } from "firebase/app";
import { getAuth } from "firebase/auth";
import { getFirestore } from "firebase/firestore";
import { getStorage } from "firebase/storage";
import firebaseConfigProd from "../firebase-prod";
import firebaseConfigDemo from "../firebase-demo";
import { getMessaging, getToken } from "firebase/messaging";
import { getAnalytics } from "firebase/analytics";

const getFirebaseConfig = () => {
  const environment = process.env.REACT_APP_ENVIRONMENT;
  ////console.log("getFirebaseConfig: "+environment)
  return environment === 'prod' ? firebaseConfigProd : firebaseConfigDemo;
};

export const app = initializeApp(getFirebaseConfig());

export const messaging = getMessaging(app);

export const auth = getAuth(app);
//export const googleProvider = new GoogleAuthProvider();

export const db = getFirestore(app);
export const storage = getStorage(app, getFirebaseConfig().storageBucket);
export const analytics = getAnalytics(app);

