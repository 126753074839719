import React, { useRef } from "react";
import { useForm } from "react-hook-form";
import { db } from "../config/firebase";
import { collection, addDoc, serverTimestamp } from "firebase/firestore";
import { useState, useEffect } from "react";
import teaserVideo from "../assets/teaservideoFI.mp4"; // Add the path to your mp4
import fourThingsImage from "../assets/4thingsFI.png"; // Add the path to your png

const suggestionsCollectionRef = collection(db, "suggestions");

const SuggestionFormFI = () => {
  const { register, handleSubmit, reset, watch } = useForm();
  const [submitted, setSubmitted] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);
 const videoRef = useRef(null);

   useEffect(() => {
      if (videoRef.current) {
        videoRef.current.playbackRate = 0.75;
        videoRef.current.addEventListener('ended', () => {
          videoRef.current.pause();  // Explicitly pause the video
          setTimeout(() => {
            videoRef.current.currentTime = 0;  // Reset to beginning
            videoRef.current.play();
          }, 10000); // Changed to 10 seconds
        });
      }

      // Cleanup listener on unmount
      return () => {
        if (videoRef.current) {
          videoRef.current.removeEventListener('ended', () => {});
        }
      };
    }, []);

  const onSubmit = async (data) => {
    setIsSubmitting(true);
    try {
      console.log("Form data:", data);
      const docRef = await addDoc(suggestionsCollectionRef, {
        ...data,
        createdAt: serverTimestamp(),
      });
      console.log("Document written with ID: ", docRef.id);
      setSubmitted(true);
      reset();
    } catch (error) {
      console.error("Error submitting form: ", error);
      // Add visible error message
      alert(`Error submitting form: ${error.message}`);
    } finally {
      setIsSubmitting(false);
    }
  };

  const values = watch();

  useEffect(() => {
    console.log("Form values:", values);
  }, [values]);

  if (submitted) {
    return (
      <div style={{ textAlign: "center", marginBottom: "10px" }}>
        <h5>Vastauksesi on tallennettu. Kiitos!</h5>
      </div>
    );
  }

  return (
    <div style={styles.container}>
      <div style={styles.mediaContainer}>
        <video 
          ref={videoRef}
          src={teaserVideo} 
          autoPlay 
          playsInline
          muted 
          style={styles.video} 
        />
      </div>
      <div style={styles.imageContainer}>
        <img src={fourThingsImage} alt="Four Things" style={styles.media} />
      </div>

      <form
        onSubmit={async (e) => {
          e.preventDefault();
          await handleSubmit(onSubmit)(e);
        }}
        style={styles.form}
      >
 <p>
  Voit osallistua kehitystyöhön lähettämällä meille ideoita allaolevalla lomakkeella. Parhaat ideat palkitaan{" "}
  <span style={{ color: "gold" }}>Premium-jäsenyydellä</span>, joten kannattaa osallistua!
</p>

        

      <label>Ehdota Slipperille uutta nimeä!</label>
      <input {...register("nameSuggestion")} type="text"  />

      <label>Mikä on itsellesi suurin syy miksi et käytä deitti- tai kohtaamissovelluksia?</label>
      <textarea {...register("reasonNotUsing")}  />

      <label>Jos voisit lisätä jonkin ominaisuuden kohtaamissovelluksiin, mikä se olisi?</label>
      <textarea {...register("reasonToUse")}  />

    
      <label>
        Annatko vielä sähköpostiosoitteesi? Emme spämmää, mutta saat tiedon kun uusi versio on käytettävissä.
      </label>
      <input {...register("email")} type="email" placeholder="Sähköpostiosoitteesi" />

      

      <button type="submit">Lähetä</button>

      <p>Saat meidät kiinni myös sivun alaosassa olevasta sähköpostiosoitteesta</p>

        <p style={styles.quote}>
          <em>"I'm not looking for a fairytale. I'm looking for someone to watch Netflix with and eat junk food."</em>
          <br />
          <span>- Unknown</span>
        </p>
      </form>
    </div>
  );
};

const styles = {
  container: {
    backgroundColor: "black",
    color: "white",
    padding: "20px",
  },
  overlay: {
    position: "relative", // Changed from absolute
    width: "100%",
    height: "100%",
    backgroundColor: "rgba(0, 0, 0, 0.5)",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
  },
  slogan: {
    fontSize: "2.5em",
    marginBottom: "10px",
  },
  subSlogan: {
    fontSize: "1.5em",
    marginBottom: "20px",
  },
  features: {
    listStyle: "none",
    padding: 0,
    marginBottom: "20px",
  },
  launch: {
    fontSize: "1.2em",
  },
  form: {
    maxWidth: "600px",
    margin: "20px auto",
    padding: "20px",
  },
  highlight: {
    color: "#ffcc00",
    fontWeight: "bold",
  },
  mediaContainer: {
    textAlign: "center",
    marginBottom: "20px",
    width: "100%",  // Changed from 100vw
    position: "relative",
    overflow: "hidden",  // Add this to prevent overflow
    ["@media (min-width: 768px)"]: {
      width: "auto",
      maxWidth: "600px",
      margin: "0 auto 20px auto",
    }
  },
  video: {
    width: "100%",  // Changed from 100vw
    maxWidth: "100%",  // Changed from 100vw
    marginBottom: "10px",
    display: "block",
  },
  media: {
    width: "100%",
    maxWidth: "100%",
    marginBottom: "10px",
    display: "block",
    ["@media (min-width: 768px)"]: {
      maxWidth: "600px",
      margin: "0 auto 10px auto",
    }
  },
  quote: {
    fontStyle: "italic",
    marginTop: "20px",
  },
};

export default SuggestionFormFI;