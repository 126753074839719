import React, { useRef } from "react";
import { useForm } from "react-hook-form";
import { db } from "../config/firebase";
import { collection, addDoc, serverTimestamp } from "firebase/firestore";
import { useState, useEffect } from "react";
import teaserVideo from "../assets/teaservideoEN.mp4";
import fourThingsImage from "../assets/4thingsEN.png";

const suggestionsCollectionRef = collection(db, "suggestions");

const SuggestionFormEN = () => {
  const { register, handleSubmit, reset, watch } = useForm();
  const [submitted, setSubmitted] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const videoRef = useRef(null);

  useEffect(() => {
    if (videoRef.current) {
      videoRef.current.playbackRate = 0.75;
      
      const handleVideoEnd = async () => {
        try {
          videoRef.current.pause();
          await new Promise(resolve => setTimeout(resolve, 10000));
          if (videoRef.current) {
            try {
              videoRef.current.currentTime = 0;
              const playPromise = videoRef.current.play();
              if (playPromise !== undefined) {
                playPromise.catch(error => {
                  console.log("Video playback error:", error);
                  // Optionally try to play again after a short delay
                  setTimeout(() => {
                    videoRef.current?.play().catch(() => {});
                  }, 1000);
                });
              }
            } catch (error) {
              console.log("Error resetting video:", error);
            }
          }
        } catch (error) {
          console.log("Video handling error:", error);
        }
      };

      videoRef.current.addEventListener('ended', handleVideoEnd);

      // Cleanup listener on unmount
      return () => {
        if (videoRef.current) {
          videoRef.current.removeEventListener('ended', handleVideoEnd);
        }
      };
    }
  }, []);

  const onSubmit = async (data) => {
    setIsSubmitting(true);
    try {
      console.log("Form data:", data);
      const docRef = await addDoc(suggestionsCollectionRef, {
        ...data,
        createdAt: serverTimestamp(),
      });
      console.log("Document written with ID: ", docRef.id);
      setSubmitted(true);
      reset();
    } catch (error) {
      console.error("Error submitting form: ", error);
      alert(`Error submitting form: ${error.message}`);
    } finally {
      setIsSubmitting(false);
    }
  };

  const values = watch();

  useEffect(() => {
    console.log("Form values:", values);
  }, [values]);

  if (submitted) {
    return (
      <div style={{ textAlign: "center", marginBottom: "10px" }}>
        <h5>Your response has been saved! Thank you!</h5>
      </div>
    );
  }

  return (
    <div style={styles.container}>
      <div style={styles.mediaContainer}>
        <video 
          ref={videoRef}
          src={teaserVideo} 
          autoPlay 
          playsInline
          muted 
          style={styles.video} 
        />
      </div>
      <div style={styles.imageContainer}>
        <img src={fourThingsImage} alt="Four Things" style={styles.media} />
      </div>

      <form
        onSubmit={async (e) => {
          e.preventDefault();
          await handleSubmit(onSubmit)(e);
        }}
        style={styles.form}
      >
        <p>
          You can contribute to the development by submitting your ideas using the form below. The best ideas will be rewarded with{" "}
          <span style={{ color: "gold" }}>Premium membership</span>, so make sure to participate!
        </p>

       
        <label>Suggest a new name for Slipper!</label>
        <input {...register("nameSuggestion")} type="text" />

        <label>What is the main reason you don't use dating or meeting apps?</label>
        <textarea {...register("reasonNotUsing")} />

        <label>If you could add any feature to meeting apps, what would it be?</label>
        <textarea {...register("reasonToUse")} />

        <label>
          Would you like to provide your email address? We won't spam you, but you'll get notified when a new version is available.
        </label>
        <input {...register("email")} type="email" placeholder="Your email address" />

        <button type="submit" disabled={isSubmitting}>
          {isSubmitting ? "Submitting..." : "Submit"}
        </button>

        <p>You can also contact us at the email address at the bottom of the page</p>

        <p style={styles.quote}>
          <em>"I'm not looking for a fairytale. I'm looking for someone to watch Netflix with and eat junk food."</em>
          <br />
          <span>- Unknown</span>
        </p>
      </form>
    </div>
  );
};

const styles = {
  container: {
    backgroundColor: "black",
    color: "white",
    padding: "20px",
  },
  overlay: {
    position: "relative", // Changed from absolute
    width: "100%",
    height: "100%",
    backgroundColor: "rgba(0, 0, 0, 0.5)",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
  },
  slogan: {
    fontSize: "2.5em",
    marginBottom: "10px",
  },
  subSlogan: {
    fontSize: "1.5em",
    marginBottom: "20px",
  },
  features: {
    listStyle: "none",
    padding: 0,
    marginBottom: "20px",
  },
  launch: {
    fontSize: "1.2em",
  },
  form: {
    maxWidth: "600px",
    margin: "20px auto",
    padding: "20px",
  },
  highlight: {
    color: "#ffcc00",
    fontWeight: "bold",
  },
  mediaContainer: {
    textAlign: "center",
    marginBottom: "20px",
    width: "100%",  // Changed from 100vw
    position: "relative",
    overflow: "hidden",  // Add this to prevent overflow
    ["@media (min-width: 768px)"]: {
      width: "auto",
      maxWidth: "600px",
      margin: "0 auto 20px auto",
    }
  },
  video: {
    width: "100%",  // Changed from 100vw
    maxWidth: "100%",  // Changed from 100vw
    marginBottom: "10px",
    display: "block",
  },
  media: {
    width: "100%",
    maxWidth: "100%",
    marginBottom: "10px",
    display: "block",
    ["@media (min-width: 768px)"]: {
      maxWidth: "600px",
      margin: "0 auto 10px auto",
    }
  },
  quote: {
    fontStyle: "italic",
    marginTop: "20px",
  }
};

export default SuggestionFormEN;