import React from "react";
import slipperLogo from "../assets/SlipperTrans512.png";
import sortingSlipper from "../assets/markoworking.jpeg";
import LanguageSwitcher from "./LanguageSwitcher";

const SlipperTeaser = () => {
  const obfuscateEmail = () => {
    const user = atob("c2xpcHBlcnR1a2k="); // "slippertuki" base64 encoded
    const domain = atob("Z21haWwuY29t"); // "gmail.com" base64 encoded
    return `${user}@${domain}`;
  };

  const email = obfuscateEmail();

  return (
    <div style={styles.page}>
      <LanguageSwitcher />
      <div style={styles.footer}>
        <p>&copy; {new Date().getFullYear()} Slipper. All rights reserved.</p>
        <p style={styles.email}>
          <a 
            href={`mailto:${email}`}
            style={styles.emailLink}
            rel="nofollow"
          >
            {email}
          </a>
        </p>
      </div>
    </div>
  );
};

const styles = {
  page: {
    fontFamily: "Arial, sans-serif",
    textAlign: "center",
    paddingTop: "20px",
    paddingBottom: "20px",
    backgroundColor: "black",
    color: "white",
  },
  header: {
    marginBottom: "30px",
  },
  logo: {
    width: "200px",
    height: "auto",
    marginBottom: "10px",
  },
  headline: {
    fontSize: "2em",
    fontWeight: "bold",
    color: "#007bff", // Use a primary color
    marginBottom: "5px",
  },
  subHeadline: {
    fontSize: "1.1em",
    color: "#666",
    marginBottom: "20px",
  },
  imageContainer: {
    width: "100%",
    marginBottom: "30px",
  },
  teaserImage: {
    width: "100%",
    maxWidth: "800px", // Limit image width for larger screens
    height: "auto",
    borderRadius: "8px",
    boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)", // Add a subtle shadow
  },
  content: {
    maxWidth: "600px",
    margin: "0 auto",
  },
  feature: {
    fontSize: "1em",
    marginBottom: "15px",
    textAlign: "left",
    paddingLeft: "20px",
  },
  quote: {
    fontStyle: "italic",
    marginBottom: "20px",
    color: "#555",
  },
  launch: {
    fontSize: "1.2em",
    fontWeight: "bold",
    color: "#28a745", // Use a success color
    marginBottom: "20px",
  },
  email: {
    marginTop: "20px",
    fontSize: "1em",
    color: "#888",
  },
  emailLink: {
    color: "#ffcc00",
    textDecoration: "none",
    cursor: "pointer",
    transition: "color 0.2s",
    "&:hover": {
      color: "#ffd700",
      textDecoration: "underline"
    }
  },
  footer: {
    marginTop: "50px",
    paddingTop: "20px",
    borderTop: "1px solid #ddd",
    color: "#888",
  },
};

export default SlipperTeaser;