/* import './App.css'; */
import React, { useEffect, useState } from 'react';
import { AuthContextProvider, UserAuth } from './context/AuthContext';
import Account from './pages/Account';
import Signin from './pages/Signin';
import Users from './pages/Users';
import Shop from './pages/Shop';
import Meets from './pages/Meets';
import Discussions from './pages/Discussions';
import Profile from './pages/Profile';
import Contact from './pages/Contact';
import ProfileCard from './pages/ProfileCard';
import RemoveAccount from './pages/RemoveAccount';
import ActionsLog from './pages/ActionsLog';
import HideProfile from './pages/HideProfile';
import HideNotifications from './pages/HideNotifications';
import Terms from './pages/Terms';
import Policy from './pages/Policy';
import Cookies from './pages/Cookies';
import MyMeetEditor from './pages/MyMeetEditor'

import Landing from './pages/NewLanding'
import SocialLanding from './pages/SocialLanding'
import SlipperTeaserLanding from './pages/SlipperTeaser'

import Chat from './pages/Chat'
import OwnMeets from './pages/OwnMeets'
import AddToHomeScreen from './pages/AddToHomeScreen'
import Admin from './pages/Admin';
import { Routes, Route, Navigate, useNavigate } from 'react-router-dom';
import { db, analytics } from "./config/firebase";
import { doc, getDoc } from 'firebase/firestore';
import { logEvent } from 'firebase/analytics'; // Import logEvent
/* import Protected from './components/Protected';
import slipperLogo from './assets/s-logo.png'
import { addBeforeInstallPromptListener, hasBeforeInstallPromptListener, removeBeforeInstallPromptListener } from './context/InstallPromptService'; */

/* function AuthRoute({ hasAuth, isLoading, children }) {
  if (isLoading) return <div>Loading...</div>;
  return hasAuth ? children : <Navigate to="/login" replace />;
}

function ProfileRoute({ hasProfile, isLoading, children }) {
  if (isLoading) return <div>Loading...</div>;
  return hasProfile ? children : <Navigate to="/login" replace />;
}

function AdminRoute({ isAdmin, isLoading, children }) {
  if (isLoading) return <div>Loading...</div>;
  return isAdmin ? children : <Navigate to="/login" replace />;
} */


function ProfileRoute({ hasProfile, children }) {
  return hasProfile ? children : <Navigate to="/login" replace />;
}

function AuthRoute({ hasAuth, children }) {
  return hasAuth ? children : <Navigate to="/login" replace />;
}

function AdminRoute({ isAdmin, children }) {
  return isAdmin ? children : <Navigate to="/login" replace />;
} 


function App() {
  const { user } = UserAuth();
  const [userHasProfile, setUserHasProfile] = useState(false);
  const [userHasAuth, setUserHasAuth] = useState(false);
  const [userIsAdmin, setUserIsAdmin] = useState(false);
  const navigate = useNavigate();



  useEffect(() => {
    const handleUserAuthentication = async () => {
      try {
        setUserHasAuth(true);
        const userRef = doc(db, "users", user.uid);
        
        const userDoc = await getDoc(userRef);
        if (userDoc.exists) {
          //console.log("Appjs: Userdoc found");
          setUserHasProfile(true);

          const isAdmin = userDoc.data().admin; // Assuming your admin field is named 'admin'
          console.log("IsAdmin "+isAdmin);
          setUserIsAdmin(isAdmin);
          // Perform additional operations when the user has a profile
          // ...

        } else {
          ////console.log("Appjs: no user profile");
          setUserHasProfile(false);
        }

        //setLoading(false); // Mark loading as complete

      } catch (error) {
        console.error("Appjs: Error handling user authentication:", error);
        setUserHasProfile(false);
        //setLoading(false); // Mark loading as complete
      }
    };

    handleUserAuthentication();
    logEvent(analytics, "user_authenticated");
  }, [user]);





  useEffect(() => {
    // Check if both conditions are met
    if (userHasAuth && userHasProfile && !userIsAdmin) {
      ////console.log("App.js: should now redirect to meets");
      navigate('/meets');
    }
    /* if (userIsAdmin) {
      ////console.log("App.js: should now redirect to meets");
      navigate('/admin');
    } */
  }, [userHasAuth, userHasProfile, userIsAdmin]);


 


  return (
    <div className='my-container'>
      <AuthContextProvider>
        {/*  {loading && <div>Loading...</div>} */}
        <Routes>

          <Route path='/' element={<SlipperTeaserLanding />} />
          <Route path='/login' element={<Landing />} />
          <Route path='/terms' element={<Terms />} />
          <Route path='/policy' element={<Policy />} />
          <Route path='/cookies' element={<Cookies />} />
          <Route path='/signin' element={<Signin />} />
          <Route path='/social' element={<SlipperTeaserLanding />} />

          <Route
            path="/admin"
            element={
              <AdminRoute isAdmin={userIsAdmin}>
                <Admin />
              </AdminRoute>
            }
          />


{/* 
<Route
            path="/meets/:meetId" 
            element={
                   <ProfileRoute hasProfile={userHasProfile}>
               <MeetDetails />
             </ProfileRoute>
            }
          /> */}

          <Route
            path="/meets"
            element={
              <AuthRoute hasAuth={userHasAuth}>
                <Meets />
              </AuthRoute>
            }
          />

          <Route
            path="/ownmeets"
            element={
              <AuthRoute hasAuth={userHasAuth}>
                <OwnMeets />
              </AuthRoute>
            }
          />

          <Route
            path="/account"
            element={
              <AuthRoute hasAuth={userHasAuth}>
                <Account />
              </AuthRoute>
            }
          />

          {/*           <Route
            path="/profilecard"
            element={
              <AuthRoute hasAuth={userHasAuth}>
                <ProfileCard />
              </AuthRoute>
            }
          /> */}

          <Route
            path="/profilecard/:uid"
            element={
              <AuthRoute hasAuth={userHasAuth}>
                <ProfileCard />
              </AuthRoute>
            }
          />


          <Route
            path="/profile"
            element={
              <AuthRoute hasAuth={userHasAuth}>
                <Profile />
              </AuthRoute>
            }
          />


          <Route
            path="/contact"
            element={
              <ProfileRoute hasProfile={userHasProfile}>
                <Contact />
              </ProfileRoute>
            }
          />
          <Route
            path="/hideprofile"
            element={
              <ProfileRoute hasProfile={userHasProfile}>
                <HideProfile />
              </ProfileRoute>
            }
          />

<Route
            path="/actionslog"
            element={
              <ProfileRoute hasProfile={userHasProfile}>
                <ActionsLog />
              </ProfileRoute>
            }
          />

          <Route
            path="/hidenotifications"
            element={
              <ProfileRoute hasProfile={userHasProfile}>
                <HideNotifications />
              </ProfileRoute>
            }
          />
          <Route
            path="/removeaccount"
            element={
              <ProfileRoute hasProfile={userHasProfile}>
                <RemoveAccount />
              </ProfileRoute>
            }
          />

          <Route
            path="/chat/:chatId"
            element={
              <ProfileRoute hasProfile={userHasProfile}>
                <Chat />
              </ProfileRoute>
            }
          />
           <Route
            path="/shop"
            element={
              <ProfileRoute hasProfile={userHasProfile}>
                <Shop />
              </ProfileRoute>
            }
          /> 
          <Route
            path="/users"
            element={
              <AuthRoute hasAuth={userHasAuth}>
                <Users />
                </AuthRoute>
            }
          />
          <Route
            path="/discussions"
            element={
              <AuthRoute hasAuth={userHasAuth}>
                <Discussions />
                </AuthRoute>
            }
          />
          <Route
            path="/addtohomescreen"
            element={
              <ProfileRoute hasProfile={userHasProfile}>
                <AddToHomeScreen />
              </ProfileRoute>
            }
          />



          <Route
            path="/mymeeteditor/:meetId?"
            element={
              <AuthRoute hasAuth={userHasAuth}>
                <MyMeetEditor />
                </AuthRoute>
            }
          />




        </Routes>
      </AuthContextProvider>
    </div>
  );
}

export default App;

