import React, { useEffect, useState } from 'react';
import { doc, getDoc, updateDoc, arrayUnion, query, collection, where, getDocs } from 'firebase/firestore';
import { ref, listAll, getDownloadURL } from 'firebase/storage';
import { db, storage } from '../config/firebase';
import { calculateAge } from '../config/utils';
import { Carousel } from 'react-responsive-carousel';
import 'react-responsive-carousel/lib/styles/carousel.min.css';
import { UserAuth } from '../context/AuthContext';
import {
  IconHeart,
  IconSmoking,
  IconPaw,
  IconGenderBigender,
  IconMapPin,
  IconUser,
  IconSchool,
  IconChefHat,
  IconHearts,
  IconBarbell,
  IconBabyCarriage,
  IconGlass,
  IconCalendarStar,
  IconHeartHandshake,
  IconBrandInstagram,
  IconBrandSnapchat,
  IconHandStop,
  IconShield,
  IconRuler2
} from '@tabler/icons-react';

const UserPreview = ({ uid }) => {
  const { user } = UserAuth();
  ////console.log("UserPreview, uid is " + uid)
  const [userData, setUserData] = useState(null);
  const [userId, setUserId] = useState(null);
  const [meetImages, setMeetImages] = useState(null);
  const userAuth = UserAuth();
  const reportingUser = userAuth.user;
  const [isFavourite, setIsFavourite] = useState(false);
  const [alreadyReported, setAlreadyReported] = useState(false);
  const [alreadyBlocked, setAlreadyBlocked] = useState(false);

  const getMeetImages = async () => {
    ////console.log("UserPreview, get meet images");
    if (uid) { // Check if uid is available
      const imagesRef = ref(storage, `meet_images/${uid}/`);
      const imageList = await listAll(imagesRef);
      const imageUrls = await Promise.all(
        imageList.items.map(async (imageRef) => {
          ////console.log("Meet image found");
          const imageUrl = await getDownloadURL(imageRef);
          return imageUrl;
        })
      );
      ////console.log("Found these meet images: " + imageUrls);
      setMeetImages(imageUrls);
    }
  };


  const cancelReporting = async () => {
    try {
      const userRef = doc(db, "users", uid);
      const userDoc = await getDoc(userRef);

      if (userDoc.exists()) {
        const userData = userDoc.data();

        // Remove reportingUser.uid from the down_votes array
        const updatedDownVotes = userData.down_votes.filter(userId => userId !== reportingUser.uid);

        // Update the user document with the modified down_votes array
        await updateDoc(userRef, { down_votes: updatedDownVotes });

        // Update the state
        setAlreadyReported(false);
      }
    } catch (error) {
      console.error("Error canceling reporting:", error);
      // Handle error as needed
    }
  };


  const makeReport = async () => {
    ////console.log("Showing report text for users: ");

    // Check if the reporting user's UID is already in the "down_votes" array

    ////console.log(userId)
    ////console.log(reportingUser.uid)
    ////console.log(uid)

    // If UID is available, update the "down_votes" array for the identified user ("badUser")
    if (uid && reportingUser.uid) {
      const userDocRef = doc(db, 'users', uid);

      try {
        const userDocSnapshot = await getDoc(userDocRef);

        if (userDocSnapshot.exists()) {
          const userData = userDocSnapshot.data();

          // Initialize the "down_votes" array if it doesn't exist
          userData.down_votes = userData.down_votes || [];

          // Add the reporting user's UID to the "down_votes" array
          userData.down_votes.push(reportingUser.uid);

          // Update the user data in the Firestore database
          await updateDoc(userDocRef, userData);
          setAlreadyReported(true);
          // Log a message indicating a successful report
          ////console.log("User reported successfully");
        }
      } catch (error) {
        // Handle any errors that might occur during the Firestore operations
        console.error("Error updating user data:", error.message);
      }
    }
  };

  const blockUser = async () => {

    try {
      // Update "blocked_by_users" array for userToBeBlocked
      const blockedUserDocRef = doc(db, 'users', uid);
      await updateDoc(blockedUserDocRef, {
        blocked_by_uids: arrayUnion(user.uid),
      });

      // Update "blocked_uids" array for the current user
      const currentUserDocRef = doc(db, 'users', user.uid);
      await updateDoc(currentUserDocRef, {
        blocked_uids: arrayUnion(uid),
      });

      const chatsQuery = query(
        collection(db, 'chats'),
        where('owner_id', 'in', [user.uid, uid]),
        where('applicant_id', 'in', [user.uid, uid])
      );

      const chatDocs = await getDocs(chatsQuery);

      // Archive all relevant chats
      const updatePromises = chatDocs.docs.map(async (chatDoc) => {
        const chatId = chatDoc.id;
        const chatDocRef = doc(db, 'chats', chatId);
        await updateDoc(chatDocRef, {
          archived: true,
        });
        ////console.log(`Chat document ${chatId} archived successfully.`);
      });

      await Promise.all(updatePromises);
      ////console.log('Document updated successfully.');
      // This is where you should add chat.chatData to the "blocked" state
      // You would typically remove the chat or hide it from the user interface.
      setAlreadyBlocked(true);
    } catch (error) {
      console.error("Error updating blocked users: ", error);
    }
  };


  useEffect(() => {
    ////console.log("UserPreview, useEffect for user");
    const getUser = async () => {
      if (uid) {
        setUserId(uid);
        const userRef = doc(db, "users", uid);
        const userDoc = await getDoc(userRef);

        if (userDoc.exists()) {
          ////console.log("UserPreview: UserDoc existed so setting the data to UserData");
          setUserData(userDoc.data());
          // Fetch meet images once you have user data
          getMeetImages();

          const currentUserDocRef = doc(db, 'users', user.uid);
          const currentUserDoc = await getDoc(currentUserDocRef);

          // Check if retrieved user is in current user's favourites
          const isFavourite = currentUserDoc.exists() && currentUserDoc.data().likes_uids && currentUserDoc.data().likes_uids.includes(uid);
          setIsFavourite(isFavourite);

          // Check if reportingUser has already reported
          if (userDoc.data().down_votes && userDoc.data().down_votes.includes(reportingUser.uid)) {
            ////console.log("User already reported");
            setAlreadyReported(true);
            return;
          }
        }
      } else {
        //console.log("No uid for preview user")
      }
    };

    getUser();
  }, [uid, reportingUser, alreadyReported, alreadyBlocked]);


  if (userData === null) {
    ////console.log("UserPreview, returning null because userData was not yet available")
    return null; // Return nothing if userData is not available yet
  }

  return (
    <div className="user-preview-container">
      {userData ? (
        <div>
          {userData.picUrl && (
            <img
              src={userData.picUrl}
              alt="User"
              style={{ borderRadius: '10px', width: '100%', height: '100%' }} // Adjust width and height as needed
            />
          )}
        </div>
      ) : (
        <p>Loading user data...</p>
      )}



      <h4>

        {userData.name}, {calculateAge(userData.birthday)}
      </h4>
      <div className="user-details">
        <div className="user-detail">
          <IconUser color={'#E5B80B'} /> {userData.gender}
        </div>
        <div className="user-detail">
          <IconMapPin color={'#E5B80B'} /> {userData.location}
        </div>

        <div className="user-detail">
          <IconRuler2 color={'#E5B80B'} /> {userData.height}
        </div>


        <div className="user-detail">
          <IconHeartHandshake color={'#E5B80B'} /> {userData.relationship_status}
        </div>
        <div className="user-detail">
          <IconCalendarStar color={'#E5B80B'} /> {userData.starSign}
        </div>
        <div className="user-detail">
          <IconGenderBigender color={'#E5B80B'} /> Haluan tavata: {userData.gender_interest}
        </div>
        <div className="user-detail">
          <IconHearts color={'#E5B80B'} /> Toivon: {userData.looking_for}
        </div>


        <div className="user-detail">
          <IconSchool color={'#E5B80B'} /> {userData.education}
        </div>
        <div className="user-detail">
          <IconChefHat color={'#E5B80B'} /> {userData.occupation}
        </div>

        <div className="user-detail">
          <IconBarbell color={'#E5B80B'} /> {userData.exercise}
        </div>
        <div className="user-detail">
          <IconGlass color={'#E5B80B'} /> {userData.alcohol}
        </div>
        <div className="user-detail">
          <IconSmoking color={'#E5B80B'} /> {userData.smoking}
        </div>
        <div className="user-detail">
          <IconBabyCarriage color={'#E5B80B'} /> {userData.kids}
        </div>
        <div className="user-detail">
          <IconPaw color={'#E5B80B'} /> {userData.pets}
        </div>






        {userData.instagram && (
          <div className="user-detail">
            <IconBrandInstagram color={'#E5B80B'} /> {userData.instagram}
          </div>
        )}
        {userData.snapchat && (
          <div className="user-detail">
            <IconBrandSnapchat color={'#E5B80B'} /> {userData.snapchat}
          </div>
        )}
        {/* <div className="user-detail">
          <IconHeart color={'#E5B80B'} /> {userData.heartPoints}
        </div> */}
        <div className="user-detail">
          <IconHandStop color={'#E5B80B'} /> {userData.blocked_by_uids.length}
        </div>
        <br />

      </div>
      {userData.bio_text && (
        <p className="user-description">
          <small style={{ whiteSpace: 'pre-wrap' }}>{userData.bio_text}</small>
        </p>
      )}

      {/*       {meetImages &&
        meetImages.map((imageUrl, index) => (
          <div>
            <img src={imageUrl} alt={`Meet Image ${index + 1}`} />
            <br /> <br />
          </div>
        ))} */}

      {meetImages &&
        meetImages.map((imageUrl, index) => (
          <div key={index}> {/* Don't forget to add a key to each element in a list */}
            <img
              src={imageUrl}
              alt={`Meet Image ${index + 1}`}
              style={{ width: '100%' }}
            />
            <br /> <br />
          </div>
        ))}

      <div>

        {alreadyReported ? (
          <div>
            <small>
              Kiitos! Olet jättänyt ilmoituksen tästä käyttäjästä. Kun tietty määrä muitakin käyttäjiä on tehnyt ilmoituksen epäasiallisesta sisällöstä tai käytöksestä, tämä käyttäjä poistetaan sovelluksesta.
            </small>
            <br /><br />
            <button onClick={cancelReporting}>Peru ilmoitus</button>
          </div>
        ) : (

          <div>
            <button onClick={makeReport}>
              <IconShield /> Tee ilmoitus käyttäjästä
            </button>

          </div>
        )}

        {/* {alreadyBlocked ? (
    <div>
      <small>
        Olet asettanut tämän käyttäjän estoon. Kaikki välisenne keskustelut on poistettu. Hän ei enää voi nähdä sinua "lähellä olevat käyttäjät"-näkymässä, eikä sinun luomiasi tapaamisia näytetä hänelle. Voit sulkea tämän näkymän.
      </small>
      <br/><br/>
      
    </div>
  ) : (
   
    <div>
   <button onClick={blockUser}>
        <IconHandStop /> Estä käyttäjä
      </button>
     
    </div>
  )} */}

        {alreadyBlocked ? (
          <div>
            <small>
              Olet asettanut tämän käyttäjän estoon. Kaikki välisenne keskustelut on poistettu. Hän ei enää voi nähdä sinua "lähellä olevat käyttäjät"-näkymässä, eikä sinun luomiasi tapaamisia näytetä hänelle. Voit sulkea tämän näkymän.
            </small>
            <br /><br />
          </div>
        ) : (
          <div>
            {isFavourite ? (
              <div>
                <small>
                  Tämän käyttäjän estäminen ei ole mahdollista, koska hän on yksi suosikkikäyttäjistäsi. Poista hänet ensin suosikeista.
                </small>
                <br /><br />
              </div>
            ) : (
              <div>
                <button onClick={blockUser}>
                  <IconHandStop /> Estä käyttäjä
                </button>
              </div>
            )}
          </div>
        )}


      </div>
      <br /><br /><br /><br /><br />

    </div>
  );
};



export default UserPreview;
