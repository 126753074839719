import React, { useEffect, useState } from 'react';
import { UserAuth } from '../context/AuthContext';
import { Link, useNavigate } from 'react-router-dom';
import { IconStar, IconUserEdit, IconDeviceTabletSearch, IconBellRinging, IconMailFast, IconUserOff, IconUserX, IconDeviceMobileUp } from '@tabler/icons-react';
import { db } from "../config/firebase";
import MeetAvatar from '../components/MeetAvatar'
import {
  query,
  where,
  getDocs,
  getDoc,
  collection,
  updateDoc,
  doc,
 
} from "firebase/firestore";
import MainNavbar from '../components/MainNavbar';
import LogoBar from '../components/LogoBar';
import slipperLogo from '../assets/SlipperIcon.png';


const Account = () => {
  const { logOut, user } = UserAuth();
  const [hasProfile, setHasProfile] = useState(false);
  const [userName, setCurrentUserName] = useState('');
  const [userBirthday, setCurrentUserBirthday] = useState('');
  const [ownerImageURL, setOwnerImageURL] = useState('');
  const [userDataRetrieved, setUserDataRetrieved] = useState(false);
  const usersCollectionRef = collection(db, "users");
  const navigate = useNavigate();
  const isMobile = true;
  const [userCount, setUserCount] = useState(0);
  const [homeScreenDialogOpen, setHomeScreenDialogOpen] = useState(false);
  const [crystals, setCrystals] = useState(0);
  const [addToHomeScreenView, setAddToHomeScreenView] = useState(false);
  const [deferredPrompt, setDeferredPrompt] = useState(null);
  const [isDeferred, setIsDeferred] = useState(false);
  const [isNotificationsOn, setIsNotificationsOn] = useState(false);
  
  const [isProfileHidden, setIsProfileHidden] = useState(false);




  const fetchUserImage = async () => {
    try {
      // Get the user's UID
      const userId = user?.uid;

      // Fetch the user's profile data from Firestore
      const q = query(usersCollectionRef, where("uid", "==", userId));
      const querySnapshot = await getDocs(q);

      if (querySnapshot.size > 0) {
        const userData = querySnapshot.docs[0].data();

        //setCrystals(userData.gems)
        if (userData.thumbUrl) {
          // Set the image URL from the user's thumbUrl
          //////console.log("Account, users thumbUrl is " + userData.thumbUrl)
          setOwnerImageURL(userData.thumbUrl);

        } else {
          // Set a default image URL if thumbUrl is not available
          setOwnerImageURL('https://www.testhouse.net/wp-content/uploads/2021/11/default-avatar.jpg');
        }
      } else {
        // Set a default image URL or handle the case when no user data is available
        setOwnerImageURL('https://www.testhouse.net/wp-content/uploads/2021/11/default-avatar.jpg');
      }
    } catch (error) {
      //////console.log('Error fetching user image:', error);
      // Set a default image URL or handle the error case
      setOwnerImageURL('https://www.testhouse.net/wp-content/uploads/2021/11/default-avatar.jpg');
    }
  };

  const fetchUserCount = async () => {
    const usersCollectionRef = collection(db, 'users');
    const querySnapshot = await getDocs(usersCollectionRef);
    const count = querySnapshot.size;
    setUserCount(count);

    //const auth = getAuth();

    /*  try {
       const userList = await listUsers(auth);
       const count = userList.length;
       setUserCount(count);
     } catch (error) {
       console.error("Error fetching user count:", error);
       // Handle error as needed
     } */
  };





  /*   useEffect(() => {
      // Fetch user image when the component mounts
      fetchUserImage();
      fetchUserCount();
  
      // Check if the install prompt has been shown before
      const hasInstallPromptBeenShown = localStorage.getItem('installPromptShown');
  
      // Handle beforeinstallprompt if it hasn't been shown before
      const handleBeforeInstallPrompt = (event) => {
        event.preventDefault();
        // Store the event for later use
        setInstallPromptEvent(event);
        // Optionally, show a button or UI element to trigger installation
        // For example, set a state to display a button
        setHomeScreenDialogOpen(true);
      };
  
  
      window.addEventListener('beforeinstallprompt', handleBeforeInstallPrompt);
  
      // If the install prompt hasn't been shown before, set up the event listener
       if (!hasInstallPromptBeenShown) {
        ////console.log("useEffect, install prompt has not been shown yet, try to show it")
        
      } 
  
      // Cleanup function for removing the event listener
      return () => {
        window.removeEventListener('beforeinstallprompt', handleBeforeInstallPrompt);
      };
    }, []);  // Only run this effect once when the component mounts */

  /* useEffect(() => {
    // Fetch user image when the component mounts
    fetchUserImage();
    fetchUserCount();
  
    // Check if the install prompt has been shown before
    const hasInstallPromptBeenShown = localStorage.getItem('installPromptShown');
  
    // Handle beforeinstallprompt if it hasn't been shown before
    const handleBeforeInstallPrompt = (event) => {
      event.preventDefault();
      setInstallPromptEvent(event);
      //setHomeScreenDialogOpen(true);
      // Optionally, you may perform some actions here if needed
    };
  
    window.addEventListener('beforeinstallprompt', handleBeforeInstallPrompt);
  
    // Cleanup function for removing the event listener
    return () => {
      window.removeEventListener('beforeinstallprompt', handleBeforeInstallPrompt);
    };
  }, []);  */

  /*   useEffect(() => {
      // Fetch user image when the component mounts
      fetchUserImage();
      fetchUserCount();
  
      // Handle beforeinstallprompt
      const handleBeforeInstallPrompt = (event) => {
        event.preventDefault();
        event.prompt(); // Trigger the browser's default prompt
        // Optionally, you may perform some actions here if needed
      };
  
      window.addEventListener('beforeinstallprompt', handleBeforeInstallPrompt);
  
      // Cleanup function for removing the event listener
      return () => {
        window.removeEventListener('beforeinstallprompt', handleBeforeInstallPrompt);
      };
    }, []); */




  // No need for handleHomeScreenClick function


  // Trigger installation when the button is clicked
  const handleAddToHomeScreen = () => {
    console.log("HandleAddToHomeScreen")
    if (deferredPrompt) {
      // Show the installation prompt
      document.querySelector('#install-button').disabled = true;
      deferredPrompt.prompt();

      // Wait for the user to respond
      deferredPrompt.userChoice.then((choiceResult) => {
        if (choiceResult.outcome === 'accepted') {
          //console.log('User accepted the A2HS prompt');
          // You can add analytics tracking here
        } else {
          //console.log('User dismissed the A2HS prompt');
        }

        // Reset the deferred prompt
        setDeferredPrompt(null);
        setIsDeferred(false);
        setAddToHomeScreenView(false);
      });
    }
  }

   /* const updateNotifications = async (newState) => {
    try {
      const userRef = doc(db, "users", user.uid);
      const userDoc = await getDoc(userRef);

      let notificationsOn;

      // Check if userDoc exists
      if (userDoc.exists) {
        notificationsOn = userDoc.data()?.notificationsOn;
      } else {
        // User document doesn't exist, set default to true
        notificationsOn = true;
      }

      const updateData = { notificationsOn: newState !== undefined ? newState : notificationsOn }; // Use existing value if newState is undefined

      await updateDoc(userRef, updateData);
      //console.log('Notifications setting updated in user document.');
    } catch (err) {
      console.error('Error updating notifications:', err);
    }
  };  */

  /* const updateProfileHidden = async (newState) => {
    try {
      const userRef = doc(db, "users", user.uid);
      const userDoc = await getDoc(userRef);

      let hide;

      // Check if userDoc exists
      if (userDoc.exists) {
        hide = userDoc.data()?.hideProfile;
      } else {
        // User document doesn't exist, set default to true
        hide = true;
      }

      const updateData = { hideProfile: newState !== undefined ? newState : hide }; // Use existing value if newState is undefined

      await updateDoc(userRef, updateData);
      //console.log('Notifications setting updated in user document.');
    } catch (err) {
      console.error('Error updating notifications:', err);
    }
  }; */

  /* 
    const updateProfileHidden = async (newState) => {
      try {
      
      // Get current user document (outside the batch)
      const userRef = doc(db, "users", user.uid);
      const userDocSnap = await getDoc(userRef);
  
      let hide;
      if (userDocSnap.exists) {
        hide = userDocSnap.data().hideProfile;
      } else {
        hide = true; // Set default if document doesn't exist
      }
    
      const batch = writeBatch(db);
        // Update current user's "liked_by_uids"
        batch.update(userRef, { liked_by_uids: [] });
        //console.log('Updated current user liked_by_uids to empty array');
    
        // Query all users (excluding current user)
        const usersQuery = query(collection(db, "users"), where("uid", "!=", user.uid));
        const querySnapshot = await getDocs(usersQuery);
    
        // Update "likes_uids" in other users' documents (within the batch)
        querySnapshot.forEach((doc) => {
          const userDocData = doc.data();
          if (userDocData.likes_uids?.includes(user.uid)) {
            
            batch.update(doc.ref, { likes_uids: arrayRemove(user.uid) });
            //console.log(`User ${userDocData.uid} - Removed ${user.uid} from likes_uids`);
          } else {
            //console.log(`User ${userDocData.uid} - No update needed (not in likes_uids)`);
          }
        });
    
        // Update hideProfile for current user (within the batch)
        batch.update(userRef, { hideProfile: newState !== undefined ? newState : hide });
        //console.log('Updated current user hideProfile');
    
        // Commit the batch write
        await writeBatch(db).commit();
    
        //console.log('User profile hidden and likes updated successfully (with batch writes)!');
      } catch (err) {
        console.error('Error hiding profile and updating likes:', err);
        // Handle errors (e.g., retry or log the error)
      }
    }; */



  /* const updateProfileHidden = async (newState) => {
    try {
      await runTransaction(db, async (transaction) => {
        const userRef = doc(db, "users", user.uid);
        const userDoc = await transaction.get(userRef);
  
        let hide;
  
        // Check if userDoc exists
        if (userDoc.exists) {
          hide = userDoc.data()?.hideProfile;
        } else {
          // User document doesn't exist, set default to true
          hide = true;
        }
  
        // Update current user's "liked_by_uids" to an empty array
        await transaction.update(userRef, { liked_by_uids: [] });
  
        // Query for all user documents
        const querySnapshot = await transaction.getDocs(collection(db, "users"));
  
        // Update "likes_uids" in other user documents
        querySnapshot.forEach((doc) => {
          if (doc.id !== user.uid && doc.data().likes_uids?.includes(user.uid)) {
            transaction.update(doc.ref, {
              likes_uids: arrayRemove(doc.data().likes_uids, user.uid),
            });
          }
        });
  
        // Update hideProfile for current user
        const updateData = { hideProfile: newState !== undefined ? newState : hide };
        transaction.update(userRef, updateData);
      });
  
      //console.log('User profile hidden and likes updated successfully!');
    } catch (err) {
      console.error('Error hiding profile and updating likes:', err);
      // Handle any necessary rollback or error messaging here
    }
  }; */



   /* const handleToggle = () => {
    console.log("Currently notifications are: "+isNotificationsOn)
    setIsNotificationsOn(!isNotificationsOn);
    console.log("So updating them to database now to: "+!isNotificationsOn)
    updateNotifications(!isNotificationsOn); // Pass userDoc and new state
  };  */

  /*  const handleHideToggle = () => {
     setIsProfileHidden(!isProfileHidden);
     updateProfileHidden(!isProfileHidden); // Pass userDoc and new state
   }; */

  useEffect(() => {
    const fetchUserData = async () => {
      if (user) {
        try {
          // Fetch user data and check if profile exists
          // Replace the following code with your own logic to check if the user has a profile
          // Set the value of hasProfile accordingly
          // For example:
          // const userData = await fetchUserData(user.uid);

          //////console.log("fetchUserData, Trying to fetch user data for user " + user.uid);
          const querySnapshot = await getDocs(
            query(usersCollectionRef, where("uid", "==", user.uid))
          );




          if (querySnapshot.size > 0) {
            const docSnap = querySnapshot.docs[0];
            const userData = docSnap.data();



            if (userData && typeof userData === "object") {
              //////console.log("Name:", userData.name); // Print the value of the "name" field
              setCurrentUserName(userData.name)
              setCurrentUserBirthday(userData.birthday)
              //////console.log("fetchUserData, Userdata was found!");
              setHasProfile(true);
              /* const notificationField = userData.notificationsOn;
              
              setIsNotificationsOn(notificationField !== undefined ? notificationField : false); */
              
            } else {
              //////console.log("fetchUserData, Invalid user data:", userData);
            }
          }

        } catch (error) {
          //console.log('Error fetching user data:', error);
        } finally {
          setUserDataRetrieved(true);
        }
      }
    };

    fetchUserData();
  }, [user]);

  useEffect(() => {

    fetchUserImage();
    fetchUserCount();

   

    const handleBeforeInstallPrompt = (event) => {
      event.preventDefault(); // Prevent default behavior
      setDeferredPrompt(event); // Store the deferred prompt
      setIsDeferred(true); // Set deferred flag
      setAddToHomeScreenView(true)
    };

    window.addEventListener('beforeinstallprompt', handleBeforeInstallPrompt);

    // Cleanup function to remove listener on unmount
    return () => {
      window.removeEventListener('beforeinstallprompt', handleBeforeInstallPrompt);
    }; 

  }, []);

  const handleSignOut = async () => {
    try {
      await logOut();
      navigate('/')
    } catch (error) {
      //////console.log(error);
    }
  };



  const handleHomeScreenDialogOpen = () => {
    //////console.log("Setting block dialog open to true")
    setHomeScreenDialogOpen(true);
  };

  const handleHomeScreenDialogClose = () => {
    //////console.log("Setting block dialog open to false")
    setHomeScreenDialogOpen(false);
  };

  /*  const HomeScreenDialog = ({ isOpen, onClose }) => {
     return (
       <dialog open={isOpen} className="universal-dialog">
         <p>Slipper on jo tietojemme mukaan lisätty aloitusruudullesi</p>
 
         {isAppInstalled ? (
           <p>Slipper on jo tietojemme mukaan lisätty aloitusruudullesi! Mutta mikäli tieto on esimerkiksi virhetilanteen vuoksi väärä, voit allaolevasta napista lisätä sen uudelleen.</p>
         ) : (
 
           <p>Pääset Slipperiin helposti ja nopeasti lisäämällä sen aloitusruudullesi.</p>
 
 
         )}
  
         <div className="button-container">
           <button onClick={onClose}>Ok</button>
          <button onClick={showInstallPrompt}>Lisää</button> 
         </div>
 
 
       </dialog>
 
     );
   }; */

  const shareLink = () => {
    const shareData = {
      title: 'Treffailu voi olla näin helppoa, kokeile:',
      text: 'https://slipper.fi',
    };

    if (navigator.share) {
      navigator.share(shareData);
    } else {
      //////console.log('Web Share API not supported');
    }
  };

  /* const handleShowUserPreview = () => {
    navigate("/profilecard");

  } */

  const toggleDefault = () => {

    setAddToHomeScreenView(false);

  };

  const handleShowUserPreview = () => {
    // Assuming user.uid is available
    const userUid = user.uid; // Replace this with the actual way you get the user's UID

    // Navigate to the "/profilecard" route with the uid as a parameter
    navigate(`/profilecard/${userUid}`);

  };

  const handleClick = () => {
    navigate('/profile');
  };

  return (
    <div className="page-container">
      <LogoBar crystals={crystals} />
      <MainNavbar />
      <strong>Profiili ja lisätoiminnot</strong>


      {/*  {homeScreenDialogOpen && (
        <div>

          <HomeScreenDialog
            isOpen={homeScreenDialogOpen}
            onClose={handleHomeScreenDialogClose}
           
          />
        </div>
      )}  */}


{addToHomeScreenView && (
        <div className="userprofile-modal">
          <div className="userprofile-content">
      
          <div
        style={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          justifyContent: 'center',
        }}
      >
       <img src={slipperLogo} alt="Slipper Logo" style={{ height: '100px' , width: 'auto'}} />
       </div>
<br/><br/>
            <p>Haluatko käyttää Slipperiä nopeammin? Lisäämällä sovelluksen laitteesi aloitusnäytölle, Slipper toimii aivan kuten natiivisovellus vaikka onkin teknisesti websivusto.
     </p>
     {/* <p>Klikkaa "Lisää Slipper avausnäytölle" ja seuraa ohjeita.
     </p> */}
           
            <div class="nearby-user-button-container">



              <button className="nearby-user-button" onClick={() => toggleDefault()}>
                 Sulje
              </button>
              <button className="nearby-user-button" onClick={() => handleAddToHomeScreen()}>
                Lisää Avausnäytölle
              </button>

            </div>


          </div>


        </div>
      )}

      <div>
        {userDataRetrieved && (
          <div>
            <br />
            {hasProfile ? (
              <div
                style={{
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',

                }}
              >
                <MeetAvatar imageUrl={ownerImageURL} name={userName} birthday={userBirthday} onClick={handleClick} />
              </div>
            ) : (
              <i> {`Sinulla ei ole vielä profiilia. Tarvitset profiilin voidaksesi luoda omia kohtaamisia, sekä ilmoittautuaksesi muiden kohtaamisiin`}
              <br/>
              <br/>
              </i>
              

            )}
            
            <br/>
          </div>
        )}

        <aside>
          <nav>

            <ul>
              {hasProfile ? (
                <>
                  <li>
                    <Link to="/profile" className="text-color-same-as-icon">
                      <IconUserEdit color={'#E5B80B'} />&nbsp; {userDataRetrieved && hasProfile ? 'Muokkaa profiiliasi' : ''}
                    </Link>

                  </li>
                  <li>
                    <a href="#" onClick={handleShowUserPreview} className="text-color-same-as-icon">
                      <IconDeviceTabletSearch color={'#E5B80B'} />&nbsp; {userDataRetrieved && hasProfile ? 'Esikatsele profiiliasi' : ''}
                    </a>
                  </li>
                  <li>
                    <Link to="/ownmeets" className="text-color-same-as-icon">
                      <IconStar color={'#E5B80B'} />&nbsp; {userDataRetrieved && hasProfile ? 'Katso omia tapaamisia' : ''}
                    </Link>
                  </li>
                  <li>

                    <Link to="/removeaccount" className="text-color-same-as-icon"><IconUserX color={'#E5B80B'} />&nbsp; Poista tili</Link>
                  </li>
                  <li>
                    <Link to="/hideprofile" className="text-color-same-as-icon">
                      <IconUserOff color={'#E5B80B'} />&nbsp; {userDataRetrieved && hasProfile ? 'Piilota profiilisi' : ''}
                    </Link>
                  </li>
                    <li>
                    <Link to="/hidenotifications" className="text-color-same-as-icon">
                      <IconBellRinging color={'#E5B80B'} />&nbsp; {userDataRetrieved && hasProfile ? 'Sähköposti-ilmoitukset' : ''}
                    </Link>
                  </li>  
                </>

              ) : (
                <li>
                  <Link to="/profile" className="text-color-same-as-icon">
                    <IconUserEdit color={'#E5B80B'} />&nbsp; {userDataRetrieved && !hasProfile ? 'Luo profiili' : ''}
                  </Link>
                </li>
              )}



              {isDeferred && (
                <li>
                  <Link onClick={handleAddToHomeScreen} className="text-color-same-as-icon">
                    <IconDeviceMobileUp color={'#E5B80B'} />&nbsp; Lisää Slipper aloitusnäytölle
                  </Link>
                </li>
              )}


              {/*  <li>

                <Link to="/terms"><IconChecklist color={'#E5B80B'} />&nbsp; Käyttöehdot ja tietosuojaseloste</Link>
              </li> */}
              <li>

                <Link to="/contact" className="text-color-same-as-icon"><IconMailFast color={'#E5B80B'} />&nbsp; Ota yhteyttä</Link>

              </li>

              {/*               <li>

                <Link to="/settings"><IconSettings color={'#E5B80B'} />&nbsp; Asetukset</Link>
              </li> */}

              {/*  <li>
             <a href="https://blog.slipper.fi" target="_blank" rel="noopener noreferrer" className="nav-link">
                  <IconHeartHandshake color={'#E5B80B'} />
                  &nbsp; Slipper-blogi
                </a> 
              </li>*/}
 
            {/*   {hasProfile && (

                <div>
                  <br />
                  <li>
                    <label for="switch">
                      <input
                        type="checkbox"
                        id="switch"
                        name="switch"
                        role="switch"
                        checked={isNotificationsOn}
                        onChange={handleToggle}
                      />
                      Push-ilmoitukset päällä
                     
                    </label>
                  </li>
        
                </div>
              )}  */}

            </ul>
          </nav>
        </aside>
        <br />
        <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center' }}>

          {/*  <img src={slipperImage} alt="Slipper Logo" /> */}





          <aside>
            <nav>

              <li>
                <button onClick={shareLink}>
                  Jaa Slipper kaverille
                </button>
              </li>
              <li>
                <button onClick={handleSignOut}>
                  Kirjaudu ulos
                </button>
              </li>
            </nav>
          </aside>
          <br />

          {userCount > 0 && <small>Slipper v{process.env.REACT_APP_VERSION} - Profiileja luotu: {userCount}</small>}

          <br /><br />
        </div>
      </div>
    </div>
  );
};

export default Account;

