import { db } from "../config/firebase";
import {
  addDoc,
  collection,
} from "firebase/firestore";
//import { sendEmail } from "./MailgunService"; // Assume you have this function set up

export const saveAction = async (senderUser, receiverUser, type, text) => {
  try {
    // Save the action
    await addDoc(collection(db, "actions"), {
      sender: senderUser,
      receiver: receiverUser,
      type: type,
      content: text,
      createdAt: new Date(),
      read: false // Marking the action as unread initially
    });

    console.log("Action saved successfully!");

  } catch (error) {
    console.error("Error saving action:", error);
  }
};




/* import { db } from "../config/firebase";
import {
  addDoc,
  collection
} from "firebase/firestore";
import { sendEmailNotification } from "./emailService"; // Import your email service function


//type can be join_meet, new_like, favourites_meet, new_msg

export const saveAction = async (senderUser, receiverUser, type, text) => {
  try {
    const newActionRef = addDoc(collection(db, "actions"), {
      sender: senderUser,
      receiver: receiverUser,
      type: type,
      content: text,
      createdAt: new Date()
    });
    console.log("Action saved successfully!");
  } catch (error) {
    console.error("Error saving action:", error);
  }
};

 */


